<script lang="ts">
export default {
    name: 'TextTooltip',
}
</script>
<script lang="ts" setup>
import { ref, watch, watchEffect, computed, nextTick } from 'vue'
import { NPopover } from 'naive-ui'
import { debounce } from 'lodash'

interface Props {
    content: string
    maxLength?: number
    tag: string
}

const props = defineProps<Props>()
const ellipsisRef = ref<HTMLDivElement | null>()
const NTooltipRef = ref<InstanceType<typeof NPopover>>()
const tooltipVisible = ref(false)

const handleUpdateShow = (value: boolean) => {
    value
        ? NTooltipRef.value?.setShow(ellipsisRef.value!.scrollWidth > ellipsisRef.value!.offsetWidth)
        : NTooltipRef.value?.setShow(value)
}
</script>
<template>
    <n-tooltip ref="NTooltipRef" trigger="hover" :delay="500" @update:show="handleUpdateShow">
        <template #trigger>
            <component v-bind="$attrs" :is="props.tag" ref="ellipsisRef" class="ellipsis">{{
                props.content
            }}</component>
        </template>
        {{ props.content }}
    </n-tooltip>
</template>
<style lang="scss" scoped>
.ellipsis {
    @include textHidden;
    letter-spacing: 1px;
}
</style>
