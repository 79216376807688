<script lang="ts">
export default {
    name: 'BaseDialog',
}
</script>
<script lang="ts" setup>
import { ref, onMounted, watch, computed } from 'vue'
import { useState } from '@/composables/useState'

interface Props {
    type?: 'default' | 'tertiary' | 'primary' | 'success' | 'info' | 'warning' | 'error'
    width?: string
    height?: string
    modelValue?: boolean
    title?: string
    cancelButtonText?: string
    confirmButtonText?: string
    isShowFooter?: boolean
}
interface Emits {
    (eventName: 'update:modelValue', params: boolean): void
    (eventName: 'confirm'): void
    (eventName: 'close'): void
}

const UPDATE_MODEL_EVENT = 'update:modelValue'

const props = withDefaults(defineProps<Props>(), {
    type: 'primary',
    width: '40%',
    height: '500px',
    title: '提示',
    cancelButtonText: '取消',
    confirmButtonText: '确认',
    isShowFooter: true,
})
const emit = defineEmits<Emits>()

const dialogVisible = ref(false)

const handleCancel = () => {
    dialogVisible.value = false
}

const handleConfirm = () => {
    emit('confirm')
}

watch(
    () => props.modelValue,
    (val) => {
        dialogVisible.value = val
    }
)

watch(dialogVisible, (val) => {
    if (!val) {
        emit(UPDATE_MODEL_EVENT, false)
        emit('close')
    }
})
</script>
<template>
    <n-modal
        v-model:show="dialogVisible"
        :mask-closable="false"
        display-directive="show"
        class="dialog z-2000"
        :style="{ width: props.width, height: props.height }"
    >
        <n-card :bordered="false" role="dialog" aria-modal="true">
            <template #header>
                <div class="dialog-title font-bold text-[22px] text-center">{{ props.title }}</div>
            </template>
            <template #header-extra>
                <n-icon size="28" class="cursor-pointer" @click="handleCancel">
                    <close></close>
                </n-icon>
            </template>
            <slot></slot>
            <template v-if="props.isShowFooter" #footer>
                <div class="text-right">
                    <n-button size="large" class="cancel-button mr-0.5" @click="handleCancel">{{
                        props.cancelButtonText
                    }}</n-button>
                    <n-button size="large" :type="props.type" @click="handleConfirm">{{
                        props.confirmButtonText
                    }}</n-button>
                </div>
            </template>
        </n-card>
    </n-modal>
</template>
<style lang="scss" scoped>
.dialog {
    .cancel-button {
        border-radius: 6px !important;
        :deep(.n-button__border) {
            border-radius: 6px !important;
        }
        :deep(n-button__state-border) {
            border-radius: 6px !important;
        }
    }
}
</style>
