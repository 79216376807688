import { defineStore } from 'pinia'
import * as API from '@/yapi'
import { transformMultipleParentTree } from '@/util'

interface AppState {
    /** 险种 */
    itype: API.GetParityApiHomeIndexResponse['itype']

    /** 菜单 */
    menu: API.GetParityApiHomeIndexResponse['menu']

    /** 优惠推广 */
    companiesPromotion: API.GetParityApiHomeIndexResponse['companiesPromotion']

    /** 筛选条件 */
    filterCondition?: any
}

export const useAppStore = defineStore('app-store', {
    state: (): AppState => ({
        itype: [],
        menu: [],
        companiesPromotion: [],
        filterCondition: {},
    }),
    actions: {
        setItype(data: API.GetParityApiHomeIndexResponse['itype']) {
            this.itype = data
        },
        setMenu(data: API.GetParityApiHomeIndexResponse['menu']) {
            this.menu = data
        },
        setCompaniesPromotion(data: API.GetParityApiHomeIndexResponse['companiesPromotion']) {
            this.companiesPromotion = data
        },
        setFilterCondition(data?: any) {
            this.filterCondition = data
        },
    },
})
