/*
 * @Descripttion: 路由文件
 * @version: V1.0
 * @Author: T00038299
 * @Date: 2022-04-20 18:01:26
 * @LastEditors: liuxiaolong
 * @LastEditTime: 2022-05-27 12:03:12
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { createPermissionGuard } from './permission'

/** 根路由: / */
export const ROOT_ROUTE = {
    path: '/',
    redirect: '/home',
    // redirect: '/staff',
}

const routes: Array<RouteRecordRaw> = [
    ROOT_ROUTE,

    // demo
    {
        path: '/demo',
        name: 'demo',
        component: () => import('../views/Demo/index.vue'),
    },

    // 主页
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/Home/index.vue'),
    },

    // 登录
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login/index.vue'),
    },

    // 资讯
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/News/index.vue'),
    },

    // 资讯
    {
        path: '/news/:id',
        name: 'newsDeatail',
        component: () => import('../views/NewsDetail/index.vue'),
    },

    // 顾问服务
    {
        path: '/service',
        name: 'service',
        component: () => import('../views/Service/index.vue'),
    },

    // 推广活动
    {
        path: '/promotion',
        name: 'promotion',
        component: () => import('../views/Promotion/index.vue'),
    },

    // 产品报价
    {
        path: '/productOffer',
        name: 'productOffer',
        component: () => import('../views/ProductOffer/index.vue'),
    },

    // 我们的产品
    {
        path: '/productList',
        name: 'productList',
        component: () => import('../views/ProductList/index.vue'),
    },

    // 个人中心
    {
        path: '/member',
        name: 'member',
        component: () => import('../views/Member/index.vue'),
    },

    // 产品筛选
    {
        path: '/productFilter',
        name: 'productFilter',
        component: () => import('../views/ProductFilter/index.vue'),
    },

    // 产品对比详情
    {
        path: '/comparisonDetail',
        name: 'comparisonDetail',
        component: () => import('../views/ComparisonDetail/index.vue'),
    },

    // 支付成功
    {
        path: '/paySuccess',
        name: 'paySuccess',
        component: () => import('../views/PaySuccess/index.vue'),
    },

    // 預約
    {
        path: '/appointment',
        name: 'appointment',
        component: () => import('../views/Appointment/index.vue'),
    },

    // 智能保险规划
    {
        path: '/smartInsurance',
        name: 'smartInsurance',
        component: () => import('../views/SmartInsurance/index.vue'),
        redirect: '/smartInsurance/home',
        children: [
            // 首页
            {
                path: 'home',
                name: 'SmartInsuranceHome',
                component: () => import('../views/SmartInsurance/Home/index.vue'),
            },
            // 问答一
            {
                path: 'question',
                name: 'SmartInsuranceQuestion',
                component: () => import('../views/SmartInsurance/Question/index.vue'),
            },
            // 问答二
            {
                path: 'question2',
                name: 'SmartInsuranceQuestion2',
                component: () => import('../views/SmartInsurance/Question2/index.vue'),
            },
            // 结果
            {
                path: 'result',
                name: 'SmartInsuranceResult',
                component: () => import('../views/SmartInsurance/Result/index.vue'),
            },
        ],
    },

    // 移动端
    {
        path: '/mobile',
        name: 'mobile',
        component: () => import('../views/Mobile/index.vue'),
        children: [
            // 预约上传
            {
                path: 'appointmentUpload/:id',
                name: 'appointmentUpload',
                meta: {
                    title: '受保人/持有人證件上傳',
                },
                component: () => import('../views/Mobile/AppointmentUpload.vue'),
            },
        ],
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    // 每次切换路由页面滚动到顶部
    scrollBehavior() {
        return { top: 0 }
    },
})

router.beforeEach(async (to, from, next) => {
    // 开始 loadingBar
    window.$loadingBar?.start()
    // 页面跳转权限处理
    await createPermissionGuard(to, from, next)
    // router.isReady()
})

router.afterEach((to) => {
    if (to.meta.title) {
        document.title = to.meta.title?.toString()
    }
    // 结束 loadingBar
    window.$loadingBar?.finish()
})

export default router
