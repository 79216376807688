<!--
 * @Descripttion: svg
 * @version: V1.0
 * @Author: 刘小龙
 * @Date: 2022-04-27 16:47:38
 * @LastEditors: liuxiaolong
 * @LastEditTime: 2022-05-09 11:15:53
-->
<script lang="ts">
export default {
    name: 'SvgIcon',
}
</script>
<script lang="ts" setup>
import { computed } from 'vue'

interface Props {
    prefix?: string
    name: string
    color?: string
    size: string
}

const props = withDefaults(defineProps<Props>(), {
    prefix: 'icon',
    name: '',
    color: '',
    size: '24',
})
const symbolId = computed(() => `#${props.prefix}-${props.name}`)

const unit = computed(() => props.size + 'px')
</script>
<template>
    <svg
        class="svg-icon"
        aria-hidden="true"
        :style="{
            color: props.color,
            fontSize: unit,
            width: unit,
            height: unit,
        }"
    >
        <use :xlink:href="symbolId" fill="currentColor" />
    </svg>
</template>
<style lang="scss">
.svg-icon {
    fill: currentColor;
    // color: bind(props.color);
}
</style>
