<!--
 * @Descripttion: App.vue
 * @version: V1.0
 * @Author: T00038299
 * @Date: 2022-04-20 17:17:17
 * @LastEditors: liuxiaolong
 * @LastEditTime: 2022-05-25 17:58:01
-->
<script setup lang="ts">
// vue
import { ref, computed, watch, onMounted, defineComponent, h, nextTick } from 'vue'

// hooks
import {
    useDialog,
    useLoadingBar,
    useMessage,
    useNotification,
    NConfigProvider,
    NDialogProvider,
    NMessageProvider,
    GlobalThemeOverrides,
    zhCN,
    dateZhCN,
} from 'naive-ui'
import { useCssVar } from '@vueuse/core'

// hooks
import useRouterPush from '@/composables/useRouterPush'
import { useLoading } from 'vue-loading-overlay'

// store
import { useAppStore } from '@/stores'
import { useUserInfo } from '@/stores/userInfo'

// utils
import { sleep } from '@/util'

// api
import * as API from '@/yapi'

const appStore = useAppStore()
const userInfoStore = useUserInfo()
const $loading = useLoading()

const themeOverrides = ref<GlobalThemeOverrides>({
    common: {
        primaryColor: '#2B09B0',
        primaryColorHover: '#2b09b0d1',
        primaryColorPressed: '#2b09b0d1',
        primaryColorSuppl: '#2b09b0d1',
    },
})

const dangerText = computed(() => appStore.itype?.map((v) => v.text).join('、'))

/**
 * 获取首页数据
 */
async function getParityApiHomeIndex() {
    const loader = $loading.show()
    try {
        const res = await API.getParityApiHomeIndex()
        appStore.setItype(res.itype)
        appStore.setMenu(res.menu)
        appStore.setCompaniesPromotion(res.companiesPromotion)

        console.log('获取首页数据', res)
    } catch (err) {
        console.error('获取首页数据', err)
    } finally {
        loader.hide()
    }
}

async function init() {
    getParityApiHomeIndex()

    // document.documentElement 是全局变量时
    const el = document.documentElement
    // const el = document.getElementById('xxx')
    const color1 = useCssVar('--el-color-primary', el)
    color1.value = '#2B09B0'
}

init()
</script>

<template>
    <n-config-provider :locale="zhCN" :date-locale="dateZhCN" :theme-overrides="themeOverrides">
        <naive-provider>
            <router-view />
            <div class="footer" style="border-top: 1px solid #ddd">
                <div class="text-center text-[#999] pt-0.2 pb-0.2">{{ dangerText }}</div>
                <div class="bg-[#2B09B0] text-center pt-0.5 pb-0.5 text-[#fff]">
                    為達最佳效能建議使用Chrome/Window7以上,Safari9以上版本,最佳瀏覽像素為 1920x1048<br />
                    ©本網站版權屬 Check價 所有
                </div>
            </div>
        </naive-provider>
    </n-config-provider>
</template>

<style lang="scss">
#_aihecong_chat_container_body
    ._aihecong_chat_button_body._aihecong_chat_button_pc
    ._aihecong_chat_button_list:last-child {
    background: url(./assets/images/service.png) !important;
}
#_aihecong_chat_container_body
    ._aihecong_chat_button_body._aihecong_chat_button_pc._aihecong_chat_container_body_displayname
    ._aihecong_chat_button_list
    h4 {
    display: none;
}
</style>
