// @ts-nocheck

import fetch from 'cross-fetch'
import router from '@/router'
import { toQuery } from 'lodash'
import { RequestBodyType, RequestFunctionParams } from 'yapi-to-typescript'

export interface RequestOptions {
    /**
     * 是否返回 Blob 结果，适用某些返回文件流的接口。
     */
    returnBlob?: boolean
}

export enum RequestErrorType {
    NetworkError = 'NetworkError',
    StatusError = 'StatusError',
    BusinessError = 'BusinessError',
}

export class RequestError extends Error {
    constructor(public type: RequestErrorType, public message: any, public httpStatusOrBusinessCode: number = 0) {
        super(message instanceof Error ? message.message : String(message))
    }
}

export default async function request<TResponseData>(
    payload: RequestFunctionParams,
    options?: RequestOptions
): Promise<TResponseData> {
    try {
        console.log('payload', payload)

        // 基础 URL，可以从载荷中拉取或者写死
        const baseUrl = process.env.NODE_ENV === 'development' ? payload.prodUrl : 'https://pass.tstechhk.com/index.php'
        // const baseUrl =
        // process.env.NODE_ENV === 'development' ? payload.prodUrl : 'http://newbtapi.ts12315.cn/index.php'

        // 完整 URL
        const url = `${baseUrl}${payload.path}`

        // headers
        const headers = {}

        // 判断如果是上传接口使用formData
        const isUploadApis = ['/ParityApi/Appointment/uploadFiles', '/ParityApi/Appointment/uploadIdCard']

        if (isUploadApis.includes(payload.path)) {
            payload.hasFileData = true
        }

        headers['X-Authorization'] =
            'Bearer eyJhbGciOiJIU21312dxkxaeUxmxInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRdsanvrubxQAXPQDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        headers['cp-client'] = 'pc'

        // fetch 选项
        const fetchOptions: RequestInit = {
            method: payload.method,
            headers: {
                ...headers,
                ...(payload.hasFileData
                    ? {}
                    : payload.requestBodyType === RequestBodyType.json
                    ? { 'Content-Type': 'application/json; charset=UTF-8' }
                    : payload.requestBodyType === RequestBodyType.form
                    ? {
                          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                      }
                    : {}),
            },
            body: payload.hasFileData
                ? payload.getFormData()
                : payload.requestBodyType === RequestBodyType.json
                ? JSON.stringify(payload.data)
                : payload.requestBodyType === RequestBodyType.form
                ? Object.keys(payload.data)
                      .filter((key) => payload.data[key] != null)
                      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(payload.data[key])}`)
                      .join('&')
                : undefined,
        }

        // console.log('fetchOptions = ', fetchOptions)

        // 发起请求
        const [fetchErr, fetchRes] = await fetch(url, fetchOptions).then<
            [
                // 如果遇到网络故障，fetch 将会 reject 一个 TypeError 对象
                TypeError,
                Response
            ]
        >(
            (res) => [null, res] as any,
            (err) => [err, null] as any
        )

        // 网络错误
        if (fetchErr) {
            messageFun(fetchRes.status, '网络错误')
            throw new RequestError(RequestErrorType.NetworkError, fetchErr)
        }

        // 状态错误
        if (fetchRes.status < 200 || fetchRes.status >= 300) {
            messageFun(fetchRes.status, '系统内部错误')
            throw new RequestError(
                RequestErrorType.StatusError,
                `${fetchRes.status}: ${fetchRes.statusText}`,
                fetchRes.status
            )
        }

        // 请求结果处理
        const res = options?.returnBlob
            ? await fetchRes.blob()
            : (fetchRes.headers.get('Content-Type') || '').indexOf('application/json') >= 0
            ? await fetchRes
                  .json()
                  // 解析 JSON 报错时给个空对象作为默认值
                  .catch(() => ({}))
            : await fetchRes.text()

        // 业务错误
        // 假设 status 为 1 时表示请求成功，其他表示请求失败，同时 message 表示错误信息
        // res.code = 601
        if (res.code === 601) {
            messageFun(res.code, '登录已失效')
            localStorage.removeItem('userInfo')
            window.sessionStorage.clear()
            // const redirectUrl = router?.currentRoute?.value?.name || 'home'
            if (location.hash.indexOf('login') === -1) {
                const redirectUrl = location.hash.split('#/')[1].replace('?', '&')
                location.href = `${location.origin}/#/login?redirectUrl=${redirectUrl}`
            }

            throw new RequestError(RequestErrorType.BusinessError, res.msg, res.code)
        }

        if (res.code >= 400 && payload.path?.indexOf('/ParityApi/Appointment/getAppointmentIDCard') === -1) {
            messageFun(res.code, res.msg)
            throw new RequestError(RequestErrorType.BusinessError, res.msg, res.code)
        }

        // 适配 dataKey，取出 data
        const data: TResponseData =
            res != null && typeof res === 'object' && payload.dataKey != null && res[payload.dataKey] != null
                ? res[payload.dataKey]
                : res

        return data
    } catch (err: unknown) {
        // 重试函数
        const retry = () => request<TResponseData>(payload, options)

        if (err instanceof RequestError) {
            // 网络错误处理
            if (err.type === RequestErrorType.NetworkError) {
                // 此处可弹窗说明原因：err.message，最好也提供重试操作，下面以原生 confirm 为例，建议替换为项目中使用到的弹窗组件
                const isRetry = confirm(`网络错误：${err.message}，是否重试？`)
                if (isRetry) {
                    return retry()
                }
                throw err
            }

            // 状态错误处理
            else if (err.type === RequestErrorType.StatusError) {
                // 用户未登录处理
                if (err.httpStatusOrBusinessCode === 401) {
                    // 推荐在此处发起登录逻辑
                    // ElMessage.error('登录已失效，请重新登录')
                    // location.href = 'http://hr-rts.corp.vipshop.com'
                }
            }

            // 业务错误处理
            else if (err.type === RequestErrorType.BusinessError) {
                console.log('err.type')
                // 推荐弹个轻提示说明错误原因：err.message
                // messageFun(err.type, err.message)
                throw err
            }
        } else {
            throw err
        }
    }
}

function messageFun(status: number, msg = '错误') {
    window.$message.destroyAll()
    // window.$message.error(msg, { duration: 0 })
    window.$message.error(msg)
}
