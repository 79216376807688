/*
 * @Descripttion: main.ts 入口文件
 * @version: V1.0
 * @Author: T00038299
 * @Date: 2022-04-20 17:17:17
 * @LastEditors: liuxiaolong
 * @LastEditTime: 2022-05-10 15:16:39
 */
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import App from './App.vue'
import { globalRegister } from './gloable'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
import BaseDialog from '@/components/business/Dialog/BaseDialog/BaseDialog.vue'
import TextTooltip from '@/components/common/TextTooltip/TextTooltip.vue'

// 1. 引入你需要的组件
import { Sticky } from 'vant'
// 2. 引入组件样式
import 'vant/lib/index.css'

import 'vue-loading-overlay/dist/css/index.css'
import 'uno.css'
import './assets/scss/index.scss'
import './assets/scss/element.scss'
import 'virtual:svg-icons-register'
import 'amfe-flexible/index.js'

const app = createApp(App)
app.use(router)
    .use(createPinia())
    .use(globalRegister)
    .use(Sticky)
    .component('svg-icon', SvgIcon)
    .component('base-dialog', BaseDialog)
    .component('text-tooltip', TextTooltip)

app.mount('#app')
